import React, { useEffect } from 'react';
import { generateShoppingCartForDelayedPayment } from '../service/shoppingCartService';
import * as sessionUtil from '../utils/sessionUtil';


const DelayedPayment = ({ location }) => {
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const reservationId = queryParams.get("reservationId")
    const expirationTimestamp = queryParams.get("timestamp");
    const hash = queryParams.get("hash");
    const sessionId = sessionUtil.createNewSessionId();
    
    const fetchData = async () => {
      await generateShoppingCartForDelayedPayment(reservationId, expirationTimestamp, hash,sessionId);
      if(typeof window !== "undefined")
        window.location.href = `/payment?sessionId=${sessionId}`
    };
    
    fetchData();
  }, [location.search]);

  return (
    <>
    </>
  );
};

export default DelayedPayment;


