// The basics
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

// Action creators and helpers
import { establishCurrentUser } from '../modules/auth';
import { isServer } from '../store';

import './App.css';
import './CSS/bootstrap.css';
import './CSS/lineicons.css';
import './CSS/styles2.css';
import './CSS/weather-icons.css';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './CSS/addon.css';

import GoogleTagManager from './components/googleTag';
import { Redirect, Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import NotFound from './routes/not-found/404';
import { GetSiteMapRoutes } from './routes/store/flight';
import { getLanguageID } from './routes/shared/functions';

import NavBar from './components/NavBar';
import faq from './routes/other/faq';
import { frontloadConnect } from 'react-frontload';
import { setRoutes, setProp } from '../modules/general';
import { setSystemText } from '../modules/general';

import { askForPermissioToReceiveNotifications } from './push-notification';
import DelayedPayment from './routes/payment-callback';

const Footer = Loadable({
  loader: () => import(/* webpackChunkName: "logout" */ './components/Footer'),
  loading: () => null,
  modules: ['footer'],
  webpack: () => [require.resolveWeak('./components/Footer')]
});

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "homepage" */ './routes/home'),
  loading: () => null,
  modules: ['homepage'],
  webpack: () => [require.resolveWeak('./routes/home')]
});

const DynamicPage = Loadable({
  loader: () => import(/* webpackChunkName: "dynamicpage" */ './routes/DynamicPage'),
  loading: () => null,
  modules: ['page'],
  webpack: () => [require.resolveWeak('./routes/DynamicPage')]
});

const blog = Loadable({
  loader: () => import(/* webpackChunkName: "blog" */ './routes/blog'),
  loading: () => null,
  modules: ['blog'],
  webpack: () => [require.resolveWeak('./routes/blog')]
});

const Profile = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ './routes/profile'),
  loading: () => null,
  modules: ['profile'],
  webpack: () => [require.resolveWeak('./routes/profile')]
});

const FlightSearch = Loadable({
  loader: () => import(/* webpackChunkName: "fs" */ './routes/flightsearch'),
  loading: () => null,
  modules: ['flightsearch'],
  webpack: () => [require.resolveWeak('./routes/flightsearch')]
});

const ReservationSummary = Loadable({
  loader: () => import(/* webpackChunkName: "rs" */ './routes/Reservation/reservationSummary'),
  loading: () => null,
  modules: ['reservationsummary'],
  webpack: () => [require.resolveWeak('./routes/Reservation/reservationSummary')]
});

const Payment = Loadable({
  loader: () => import(/* webpackChunkName: "payment" */ './routes/Reservation/Payment'),
  loading: () => null,
  modules: ['payment'],
  webpack: () => [require.resolveWeak('./routes/Reservation/reservationSummary')]
});

const PaymentReturn = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ './routes/Reservation/PaymentReturn'),
  loading: () => null,
  modules: ['paymentreturn'],
  webpack: () => [require.resolveWeak('./routes/Reservation/PaymentReturn')]
});

const Contact = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ './routes/Contact'),
  loading: () => null,
  modules: ['contactpage'],
  webpack: () => [require.resolveWeak('./routes/Contact')]
});

const Invoice = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ './routes/Reservation/invoice'),
  loading: () => null,
  modules: ['invoice'],
  webpack: () => [require.resolveWeak('./routes/Reservation/invoice')]
});

const rentacar = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ './routes/rentacar/Rentacar'),
  loading: () => null,
  modules: ['rentacar'],
  webpack: () => [require.resolveWeak('./routes/rentacar/Rentacar')]
});

const review = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ './routes/review/Review'),
  loading: () => null,
  modules: ['review'],
  webpack: () => [require.resolveWeak('./routes/review/Review')]
});

const reservationInfo = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ './routes/Reservation/reservationInfo'),
  loading: () => null,
  modules: ['reservationInfo'],
  webpack: () => [require.resolveWeak('./routes/Reservation/reservationInfo')]
});

const AgentLogin = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ './routes/agentlogin'),
  loading: () => null,
  modules: ['agentlogin'],
  webpack: () => [require.resolveWeak('./routes/agentlogin')]
});

const Myinfo = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ './routes/user/myinfo'),
  loading: () => null,
  modules: ['myinfo'],
  webpack: () => [require.resolveWeak('./routes/user/myinfo')]
});

const Reslist = Loadable({
  loader: () => import(/* webpackChunkName: "reslist" */ './routes/user/reslist'),
  loading: () => null,
  modules: ['reslist'],
  webpack: () => [require.resolveWeak('./routes/user/reslist')]
});

const Benefits = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ './routes/user/benefits'),
  loading: () => null,
  modules: ['benefits'],
  webpack: () => [require.resolveWeak('./routes/user/benefits')]
});

const Password = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ './routes/user/password'),
  loading: () => null,
  modules: ['Password'],
  webpack: () => [require.resolveWeak('./routes/user/password')]
});

const Passwordreset = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */ './routes/passwordreset'),
  loading: () => null,
  modules: ['Passwordreset'],
  webpack: () => [require.resolveWeak('./routes/passwordreset')]
});

const Faq = Loadable({
  loader: () => import(/* webpackChunkName: "faq" */ './routes/other/faq'),
  loading: () => null,
  modules: ['agentlogin'],
  webpack: () => [require.resolveWeak('./routes/other/faq')]
});

const event = { platform: 'react-stack' };

const tagManagerArgs = { gtmId: 'GTM-N7Z3V8G' };

//TagManager.initialize(tagManagerArgs);

async function frontload(props) {
  let promise = new Promise((resolve, reject) => {
    GetSiteMapRoutes('3', '').then(val => {
      props.setRoutes(val.data.d);
      var currentdate = new Date();
      var datetime =
        'Last Sync gemr: ' +
        currentdate.getDate() +
        '/' +
        (currentdate.getMonth() + 1) +
        '/' +
        currentdate.getFullYear() +
        ' @ ' +
        currentdate.getHours() +
        ':' +
        currentdate.getMinutes() +
        ':' +
        currentdate.getSeconds();

      if (props && props.router && props.router.location) {
        let search = props.router.location.search ? props.router.location.search : '';
        let currentPath = props.router.location.pathname + search;
      }
    });

    resolve('ok');
  });
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedData: {}
    };
    this.superheroElement = React.createRef();
  }
  componentWillMount() {
    if (!isServer) {
      //this.props.establishCurrentUser();
    }
  }

  async componentDidMount() {

  }
  render() {
    let currentPath = this.props.router.location.pathname;
    return (
      <div>
        <NavBar
          location={this.props.location}
          languageID={getLanguageID()}
          sitemap={this.props && this.props.general && this.props.general.routes ? this.props.general.routes : {}}
        />
        <div className="_mh-500">
          <Switch>
            <Redirect from="/:url*(/+)" to={currentPath.slice(0, -1)} />
            <Route exact path="/" component={Home} selectedData={this.state.selectedData} />
            <Route exact path="/page/:id" component={DynamicPage} />
            <Route exact path="/goedkope-vliegtickets/:from" component={DynamicPage} />
            <Route exact path="/goedkope-vliegtickets/:from/:to" component={DynamicPage} />
            <Route exact path="/last-minute/:tocountry" component={DynamicPage} />
            <Route exact path="/last-minute/:tocountry/:to" component={DynamicPage} />
            <Route exact path="/maatschappij/:airline" component={DynamicPage} />
            <Route exact path="/maatschappij/:airline/:subject" component={DynamicPage} />
            <Route exact path="/regio/:country/:city" component={DynamicPage} />
            <Route exact path="/turkije/:regio/:city" component={DynamicPage} />
            <Route exact path="/turkije/:regio" component={DynamicPage} />
            <Route exact path="/vliegtickets/:subject" component={DynamicPage} />
            <Route exact path="/blog/:page" component={DynamicPage} />
            <Route exact path="/vliegvelden/:subject" component={DynamicPage} />
            <Route exact path="/cookie" component={DynamicPage} />
            <Route exact path="/vliegvelden" component={DynamicPage} />
            <Route exact path="/annuleringsdekking" component={DynamicPage} />
            <Route exact path="/tripflex" component={DynamicPage} />
            <Route exact path="/tripsms" component={DynamicPage} />
            <Route exact path="/over-ons" component={DynamicPage} />
            <Route exact path="/coronavirus" component={DynamicPage} />
            <Route exact path="/vacature" component={DynamicPage} />
            <Route exact path="/laagste-prijsgarantie" component={DynamicPage} />
            <Route exact path="/ruim-aanbod-luchtvaartmaatschappijen" component={DynamicPage} />
            <Route exact path="/verzorgt-vliegtickets-voor-meer-dan-50000-passagiers-per-jaar" component={DynamicPage} />
            <Route exact path="/reizen-zonder-ticket" component={DynamicPage} />
            <Route exact path="/nederlands-klantenservice" component={DynamicPage} />
            <Route exact path="/ervaringen" component={DynamicPage} />
            <Route exact path="/algemene-voorwaarden" component={DynamicPage} />
            <Route exact path="/goedkope-vliegtickets" component={DynamicPage} />
            <Route exact path="/definitief-en-bindend-boeking" component={DynamicPage} />
            <Route exact path="/maatschappijen" component={DynamicPage} />
            <Route exact path="/vliegtickets/turkije/:to" component={DynamicPage} />
            <Route exact path="/vliegtickets/:from/:to" component={FlightSearch} />
            <Route exact path="/vliegtickets/:tocountry/:from/:to" component={FlightSearch} />
            <Route exact path="/FlightSearch" component={FlightSearch} />
            <Route exact path="/vliegvelden" component={FlightSearch} />
            <Route exact path="/ReservationSummary" component={ReservationSummary} />
            <Route exact path="/invoice" component={Invoice} />
            <Route exact path="/InvoiceShow" component={Invoice} location={this.props.location} />
            <Route exact path="/FlightSearch" component={FlightSearch} />
            <Route exact path="/maatschappijen" component={FlightSearch} />
            <Route exact path="/Payment" component={Payment} />
            <Route exact path="/PaymentReturn" component={PaymentReturn} />
            <Route exact path="/page/:id" component={DynamicPage} />
            <Route exact path="/uw-reservering" component={reservationInfo} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/rentacar" component={rentacar} />
            <Route exact path="/login" component={AgentLogin} />
            <Route exact path="/blog" component={blog} />
            <Route exact path="/review" component={review} />
            <Route path="/vragen" component={faq} />
            <Route path="/user/benefits" component={Benefits} />
            <Route path="/user/myinfo" component={Myinfo} />
            <Route path="/user/reslist" component={Reslist} />
            <Route path="/user/password" component={Password} />
            <Route exact path="/profile/:id" component={Profile} />
            <Route path="/passwordreset" component={Passwordreset} />
            <Route path="/delay-payment-callback" component={DelayedPayment}/>
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
        <Footer
          location={this.props.location}
          sitemap={this.props && this.props.general && this.props.general.routes ? this.props.general.routes : {}}
        />
        <GoogleTagManager gtmId="GTM-N7Z3V8G" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state };
}

const mapDispatchToProps = dispatch => bindActionCreators({ setRoutes, setSystemText, setProp }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(withRouter(App))
);
